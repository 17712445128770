html,
body {
  color: black !important;
  font-family: Poppins !important;
  font-size: 12px !important;
}

.columns {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.column {
  padding: 0.75rem 0 0.75rem 0 !important;
}
